import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Tags from "./tags"
import Dropdown from 'react-bootstrap/Dropdown';
import { slugify } from "../utils/ug-utils"

import "../styles/facet.scss"

const Facet = ({ type, title, data, urlPrefix, useHashtag, selected, ...rest }) => {
  let result = ""
  if (type === "list") {
    result = (
      <>
        {title && <h4>{title}</h4>}
        <Tags
          tagData={data}
          urlPrefix={urlPrefix}
          useHashtag={useHashtag}
          withId={false}
          selected={selected}
          {...rest}
        />
      </>
    )
  }

  const handleOnChange = (e) => {
    const url = e.target.value
    if (url) {
      navigate(url)
      return false
    }
  }

  if (type === "select") {
    result = (
      <>
        <Dropdown onChange={handleOnChange}>
          <Dropdown.Toggle variant="outline-primary" size="lg">
            {`- ${title} -` || `- Select... -`}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {data?.map((tag) => {
                // Allow for TaxonomyTerm (with id ane name) or just strings.
                const id = tag.id || tag
                const name = tag.name || tag
                const url = tag.url || `${urlPrefix}/${slugify(name)}`
                const props = {
                  key: id,
                  value: url,
                }
                if (id === selected) {
                  props.selected = `selected`
                }
                return <Dropdown.Item {...props}>{`${useHashtag ? "#" : ""}${name}`}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }
  return <div class="facet">{result}</div>
}

export default Facet

Facet.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  urlPrefix: PropTypes.string,
  useHashtag: PropTypes.bool,
  selected: PropTypes.string,
}
Facet.defaultProps = {
  type: "list",
  useHashtag: true,
  selected: "",
}
