import React from "react"
import PropTypes from "prop-types"
import Link from "./shared/link"
import "../styles/tags.scss"

import { slugify } from "../utils/ug-utils"

function Tags({
  tagData,
  urlPrefix,
  useHashtag,
  withId,
  className,
  ulClassName,
  liClassName,
  linkClassName,
  linkSelectedClassName,
  selected,
  ...rest
}) {
  return tagData.length ? (
    <div id={withId ? "tags" : null} className={className} {...rest}>
      <ul className={ulClassName}>
        {tagData?.map((tag) => {
          const id = tag.id || tag
          const name = tag.name || tag
          /* const url = tag.url || `${urlPrefix.replace('/tags', '')}?q=${encodeURIComponent(name)}` */
          const label = `${useHashtag ? "#" : ""}${name}`
          const props = {
            key: id,
          }
          let _linkClassName = linkClassName
          if (id === selected) {
            props.className = `selected`
            _linkClassName = linkSelectedClassName || _linkClassName
          }
          return (
            <li className={liClassName} {...props}>
              {/* <Link className={_linkClassName} to={url}> */}
                <span class="badge bg-light">{label}</span>
              {/* </Link> */}
            </li>
          )
        })}
      </ul>
    </div>
  ) : null
}

Tags.propTypes = {
  tagData: PropTypes.array,
  urlPrefix: PropTypes.string,
  useHashtag: PropTypes.bool,
  withId: PropTypes.bool,
  className: PropTypes.string,
  ulClassName: PropTypes.string,
  liClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  linkSelectedClassName: PropTypes.string,
  selected: PropTypes.string,
}
Tags.defaultProps = {
  tagData: [],
  urlPrefix: "/tags",
  useHashtag: true,
  withId: true,
  className: "tags",
  ulClassName: "lead",
  selected: "",
}

export default Tags
