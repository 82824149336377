import React from "react"
import Card from "./card"

const ArticleTeaser = ({ node, className }) => {
  const url = node?.path?.alias || "#"
  const type = node.relationships.field_news_category[0]?.name

  var imageData
  if (className === "masonry-card") {
    imageData =
      node._masonry_card_image?.field_hero_image?.relationships.field_media_image.localFile.childImageSharp
        .gatsbyImageData
  } else {
    imageData =
      node.relationships._card_image?.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData
    className = "article-teaser-card"
  }

  const image = {
    gatsbyImageData: imageData,
    alt: node.relationships.field_hero_image?.field_media_image.alt || "Article Image",
  }

  return (
    <Card
      cardStyle="aad-masonry"
      title={node.title}
      url={url}
      image={image}
      subtitle={type}
      bgImageOpacity="100"
      duration={node.field_reading_time}
      link_class="news-link"
      className={className}
      aria-label="Article Teaser Card"
    />
  )
}

Card.defaultValues = {
  className: "",
}

export default ArticleTeaser
